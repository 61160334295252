import React from 'react';
import {Container, Grid, Typography} from "@mui/material";
import {Monthly} from "./Monthly";
import {Quarterly} from "./Quarterly";
import {Semiannual} from "./Semiannual";

function Subscription() {
    const style = {
        bgcolor: 'lightgray',
        p: 2,
    };

    const titleStyle = {
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        ...style,
    };

    const planStyle = {
        minHeight: '100%',
        marginTop: '16px',
        p: 2,
        ...style,
    };

    return (
        <Container component="main" maxWidth="lg" className="term">
            <Grid sx={titleStyle}>
                <Typography component="h1" variant="h5" align="left"
                            style={{fontWeight: '500', textAlign: 'center'}}>
                    Planos de Assinatura
                </Typography>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="stretch">
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Monthly sx={{...planStyle, borderBottomLeftRadius: '10px'}} price='37,00'/>
                    {/*</Box>*/}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Quarterly sx={{...planStyle}} price='97,00' monthly='32,33'
                               discount="12"/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Semiannual sx={{...planStyle}} price='157,00' monthly='26,17'
                                discount="29"/>
                </Grid>
                {/*<Grid item xs={12} sm={12} md={6} lg={3}>*/}
                {/*    <Annual sx={{...planStyle, borderBottomRightRadius: '10px'}} price='516,00' monthly='43,00'*/}
                {/*            discount="35"/>*/}
                {/*</Grid>*/}
            </Grid>
        </Container>
    );
}

export default Subscription;
